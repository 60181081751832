<template>

  <div class="order_item_common"
       v-for="(order_item, order_index) in orderList.data" :key="order_index">
    <div class="head_wrap">
      <div class="selection">
        <el-checkbox v-model="order_item.checked"
                     :disabled="orderItemCheckBoxDisabledNotify(order_item)"
                     @change="selectionChangeHandler"></el-checkbox>
      </div>
      <div class="no">订单编号：{{ order_item.order_sn }}</div>
      <div class="date">下单时间：{{ order_item.created_at_local }}</div>
      <div class="countdown" v-if="order_item.state_text === '待支付'"> 请在
        <span style="color:#F51C21;">
                  <Countdown :remainTime="order_item.cancel_seconds" :orderSn="order_item.order_sn"
                             @countDowmEnd="countDownEnd(order_item)"></Countdown>
                </span> 内完成支付
      </div>
    </div>
    <div class="content_wrap" style="min-height: 140px;">
      <div class="items">
        <div v-for="(goods_item, goods_index) in order_item.order_goods" :key="goods_index">
          <div class="course_item_common pointer" v-if="goods_item.goods_type == 'curriculum'" :style="goods_index === 0 ? 'margin-top: 0;' : 'margin-top:20px;'">
            <div class="cover" @click="$emit('curDetailHandler', goods_item.goods_info.cur_uuid)">
              <img :src="goods_item.cur_cover"/>
              <span class="hot" v-if="goods_item.cur_label && goods_item.cur_label != 'null'">{{goods_item.cur_label}}</span>
              <span class="tag" v-if="goods_item.cur_type">{{ goods_item.cur_type }}</span>
            </div>
            <div class="content">
              <h4 class="text_ellipsis"
                  :title="goods_item.goods_info.name" @click="$emit('curDetailHandler', goods_item.goods_info.cur_uuid)">{{ goods_item.goods_info.name }}</h4>
              <div class="info">
                章节：{{ goods_item.chapter_desc }}
              </div>
            </div>
          </div>
          <div v-if="goods_item.goods_type == 'vip'">
            <VipCard :vip_card="goods_item.goods_info"
                     :expired_at="goods_item.goods_info.expired_detail_text"
                     :price_visible="false" :btn_buy="false"
                     :btn_detail="false" :margin_bottom_0="true"></VipCard>
          </div>
        </div>
      </div>
      <div class="price">
        <div class="wrap" style="width: 120px;">
          <span>价格：¥ {{ order_item.total_amount }}</span>
          <span>优惠：¥ {{ parseFloat((order_item.discount_amount + order_item.reduce_amount).toFixed(2)) }}</span>
          <span>需付：<span style="color:#F51C21;">¥ {{ order_item.order_amount }}</span></span>
        </div>
      </div>
      <div class="btns">

        <div class="wrap" v-if="order_item.state_text === '待支付'">
          <el-button type="danger" @click="payOrderHandler(order_item.uuid)">去支付</el-button>
          <el-button type="text" @click="cancelOrderHandler(order_item.uuid)">取消订单</el-button>
        </div>

        <div class="wrap" v-else-if="order_item.state_text === '已支付'">
          <span>已完成</span>
        </div>

        <div class="wrap" v-else-if="order_item.state_text === '已取消'">
          <span>已取消</span>
          <el-button type="text" @click="deleteOrderHandler(order_item.uuid)">删除</el-button>
        </div>

        <div class="wrap" v-else-if="order_item.state_text === '待收款'">
          <span>待收款</span>
          <el-button type="text" @click="payOrderHandler(order_item.uuid)">重新选择支付方式</el-button>
        </div>

        <div class="wrap" v-else-if="order_item.state_text === '已关闭'">
          <span>已关闭</span>
          <el-button type="text" @click="deleteOrderHandler(order_item.uuid)">删除</el-button>
        </div>

      </div>
    </div>
  </div>

  <!-- 分页 -->
  <Pagination :pager="orderList.pager" @getPager="getPager" v-if="orderList.pager.total > 0">
    <el-dropdown class="pagination_dropdown" trigger="click" @command="orderCommandHandler">
              <span class="el-dropdown-link">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="pay" :disabled="cmdPayDisabled">支付</el-dropdown-item>
          <el-dropdown-item command="del" :disabled="cmdDelDisabled">删除</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </Pagination>
  <el-empty :description="emptyDesc" v-if="orderList.pager.total == 0"></el-empty>

</template>

<script>
  import Countdown from 'components/common/Countdown'
  import Pagination from 'components/common/Pagination'
  import VipCard from "components/settlement/VipCard"
  export default {
    components: {
      Countdown,
      Pagination,
      VipCard
    },
    props: {
      orderList: {
        type: Object,
        default: null
      },
      emptyDesc: {
        type: String,
        default: '暂无课程订单'
      },
      cmdPayDisabled: {
        type: Boolean,
        default: true
      },
      cmdDelDisabled: {
        type: Boolean,
        default: true
      },
    },
    data() {
        return {
            selectedRows: [],
        }
    },
    mounted() {
//         console.log('components-OrderRecordList->', this.orderList);
    },
    methods: {
      orderItemCheckBoxDisabledNotify(order_item){
        return ['待收款', '已支付'].includes(order_item.state_text);
      },
      getPager(pager) { //获取分页变化后数据
        this.$emit('getPagerHandler', pager)
      },
      orderCommandHandler(command) {  //批量操作
        this.$emit('orderCommandHandler', command)
      },
      notifySelectedRows(){

        this.selectedRows = [];

        const that = this;
        this.orderList.data.forEach(item => {

          if (item.checked) {
            that.selectedRows.push(item);
          }

        });

      },
      selectionChangeHandler(val) {

        this.notifySelectedRows();
        this.notifyBatchCMDBtn();

        this.$emit('selectionChangeHandler', this.selectedRows)
      },

      notifyBatchCMDBtn(){
        // 统计选中订单的状态数量
        let unpay_count = 0;
        let canceled_count = 0;
        let uncashed_count = 0;
        let payed_count = 0;
        let closed_couont = 0;
        this.selectedRows.forEach(item => {

          switch (parseInt(item.state)) {
            case 1 : { //'待支付'
              unpay_count++;
            }
              break;
            case 2 : {//已取消
              canceled_count++;
            }
              break;
            case 3 : {//待收款
              uncashed_count++;
            }
              break;
            case 4 : {//已支付
              payed_count++;
            }
              break;
            case 5 : {//已关闭
              closed_couont++;
            }
              break;
          }

        });

        let multi_pay_disabled = false;
        let multi_delete_disabled = false;

        if (unpay_count > 0 &&
          canceled_count === 0 && uncashed_count === 0 && payed_count === 0 && closed_couont === 0) {
          multi_pay_disabled = false;
        } else {
          multi_pay_disabled = true;
        }
        if ((closed_couont + canceled_count) > 0 && uncashed_count === 0 && payed_count === 0 && unpay_count === 0) {
          multi_delete_disabled = false;
        } else {
          multi_delete_disabled = true;
        }

        this.$emit('notifyBatchBtn', multi_pay_disabled, 'pay');
        this.$emit('notifyBatchBtn', multi_delete_disabled, 'delete');
      },

      countDownEnd(order_item) {
        this.$emit('countDownEndHandler', order_item)
      },
      payOrderHandler(order_uuid){
          this.$emit('payOrderHandler', order_uuid)
      },
      cancelOrderHandler(order_uuid){
        this.$emit('cancelOrderHandler', order_uuid)
      },
      deleteOrderHandler(order_uuid){
        this.$emit('deleteOrderHandler',order_uuid)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .info_sm {
    font-size:12px;
    display:flex;
    margin-bottom:20px;
    span {
      flex: 1;
    }
  }
</style>
