<template>
  <div>
    <div class="well_frame">
      <el-tabs v-model="tabActiveName" class="tabs_demo">

        <!-- 课程订单 -->
        <el-tab-pane label="课程订单" name="curriculum"  v-loading="loading_data">
          <OrderRecordList :orderList="orderList" v-if="tabActiveName=='curriculum'"
                           :cmdPayDisabled="orderList.cmd_pay_disabled"
                           :cmdDelDisabled="orderList.cmd_del_disabled"
                           @notifyBatchBtn="notifyBatchBtn"
                           @cur-detail-handler="goCurDetail"
                           @get-pager-handler="getPager"
                           @order-command-handler="handleCommandOrder"
                           @selection-change-handler="selectionChangeHandler"
                           @count-down-end-handler="countDownEnd"
                           @pay-order-handler="payOrderHandler"
                           @cancel-order-handler="cancelOrderHandler"
                           @delete-order-handler="deleteOrderHandler"></OrderRecordList>
        </el-tab-pane>

        <!-- 会员卡订单 -->
        <el-tab-pane label="会员卡订单" name="vip"  v-loading="loading_data">
          <OrderRecordList :orderList="orderList" v-if="tabActiveName=='vip'"
                           :cmdPayDisabled="orderList.cmd_pay_disabled"
                           :cmdDelDisabled="orderList.cmd_del_disabled"
                           @notifyBatchBtn="notifyBatchBtn"
                           emptyDesc="暂无会员卡订单"
                           @get-pager-handler="getPager"
                           @order-command-handler="handleCommandOrder"
                           @selection-change-handler="selectionChangeHandler"
                           @count-down-end-handler="countDownEnd"
                           @pay-order-handler="payOrderHandler"
                           @cancel-order-handler="cancelOrderHandler"
                           @delete-order-handler="deleteOrderHandler"></OrderRecordList>
        </el-tab-pane>

        <el-tab-pane label="混合订单" name="mix" v-if="false"  v-loading="loading_data">
          <OrderRecordList :orderList="orderList" v-if="tabActiveName=='mix'"
                           :cmdPayDisabled="orderList.cmd_pay_disabled"
                           :cmdDelDisabled="orderList.cmd_del_disabled"
                           @notifyBatchBtn="notifyBatchBtn"
                           @cur-detail-handler="goCurDetail"
                           @get-pager-handler="getPager"
                           @order-command-handler="handleCommandOrder"
                           @selection-change-handler="selectionChangeHandler"
                           @count-down-end-handler="countDownEnd"
                           @pay-order-handler="payOrderHandler"
                           @cancel-order-handler="cancelOrderHandler"
                           @delete-order-handler="deleteOrderHandler"></OrderRecordList>
        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>
  import OrderRecordList from 'views/pc/front/center/include/OrderRecordList'

  import {listOrder, cancelOrder, closeOrder, deleteOrder, fmtUserOrderInfo} from 'api/settlement/order/user'
  import {preparePayment} from 'api/settlement/payment/user'
  import {db_time_to_local_fmt} from 'utils/timeutils'
  import { tableConfigBase, getPagerOffsetLimit } from 'utils/common'
  import { showConfirmMessageBox, showMessage } from 'api/base/message'
  import { ElMessage } from 'element-plus'
  export default {
    components: {
        OrderRecordList
    },
    data() {
      return {
        loading_data: true,
        tabActiveName: 'curriculum',
        orderList: {
          data:[],
          selectedRows:[],
          cmd_pay_disabled: true,
          cmd_del_disabled: true,
          pager: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
          }
        },
      }
    },
    created() {
      this.loadOrderList();
    },
    methods: {
      async loadOrderList(){

        let pager_offset_limit = getPagerOffsetLimit(this.orderList.pager);
        let filter_data = {
          type: this.tabActiveName,
        };

        filter_data.offset = pager_offset_limit['offset'];
        filter_data.limit = pager_offset_limit['limit'];

        const that = this;
        this.loading_data = true;
        await listOrder(filter_data).then(result => {

          if (result.succeed) {

              result.data.forEach(item=>{
                let filled_item = fmtUserOrderInfo(item);

                if (filled_item.state_text === '待支付' && filled_item.cancel_seconds < 0) {
                  filled_item.state_text = '已关闭';
                }

              });

              this.orderList.data = result.data;
              this.orderList.pager.total = result.count;

            this.orderList.cmd_del_disabled = true;
            this.orderList.cmd_pay_disabled = true;

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

          this.loading_data = false;

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      goPath(path) {
        this.$router.push(path);
      },
      getPager(pager) { //获取分页变化后数据
        console.log(pager);
        this.loadOrderList();
      },
      handleCommandOrder(command) {  //批量操作

        let selected_order_uuids = [];

        this.orderList.selectedRows.forEach(item => {
          selected_order_uuids.push(item.uuid);
        });

        if (selected_order_uuids.length > 0){

          switch (command) {
            case 'pay': { //合并支付

              preparePayment(selected_order_uuids).then(result=>{

                if (result.succeed) {
                    this.goPath(`/order/pay/mixed/${result.confirm_key}`);
                } else {
                  showMessage(result.error_msg, 'warning');
                }

              }).catch(err=>{
                  showMessage(err, 'error');
              })

            } break;
            case 'del': { //批量删除订单
              this.deleteOrderByUUIDS(selected_order_uuids);
            } break;
          }

        }

      },
      goCurDetail(cur_uuid){
        const cur_detail_page = this.$router.resolve({name: 'CourseDetail', params: {uuid: cur_uuid}});
        window.open(cur_detail_page.href, '_blank');
      },
      selectionChangeHandler(selectedRows) {
        console.log('selectionChangeHandler',selectedRows);
        this.orderList.selectedRows = selectedRows
      },
      countDownEnd(order_item) {

        console.log('time end', order_item.order_sn);

        const that = this;
        closeOrder({'uuid_in': [order_item.uuid]}).then(result => {

          if (result.succeed) {

            ElMessage({
              dangerouslyUseHTMLString: true,
              message: `<p>订单<strong>${order_item.order_sn}</strong>已经超过24小时未支付,系统已自动关闭订单`
            });

            that.loadOrderList();

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      payOrderHandler(order_uuid){
         this.goPath(`/order/pay/${order_uuid}/?from=order_center`);
      },
      async cancelOrderHandler(order_uuid){

        const that = this;

        showConfirmMessageBox('确定要取消该订单吗?').then(() => {
          cancelOrder({'uuid_in': [order_uuid]}).then(result => {

            if (result.succeed) {

              that.loadOrderList();

            } else {
              that.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定'
              });
            }

          }).catch(err => {
            that.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });
        }).catch(()=>{});

      },
      deleteOrderHandler(order_uuid){
          this.deleteOrderByUUIDS([order_uuid]);
      },
      deleteOrderByUUIDS(uuid_list){

        let confirm_msg = uuid_list.length > 1 ? '确定要删除选中的订单吗?' : '确定要删除该订单吗?';

        showConfirmMessageBox(confirm_msg).then(() => {

            deleteOrder({ 'uuid_in': uuid_list})
              .then(result=>{
                  if(result.succeed){
                      this.loadOrderList();
                  } else {
                      showMessage(result.error_msg, '`');
                  }
              })
              .catch(err=>{
                  showMessage(err, 'error');
              })

        }).catch(()=>{});
      },
      notifyBatchBtn(val, btn_command){

          switch(btn_command){
            case 'pay': {
                this.orderList.cmd_pay_disabled = val;
            } break;
            case 'delete': {
                this.orderList.cmd_del_disabled = val;
            } break;
          }

      }
    },
    watch: {
        tabActiveName(val){
           this.orderList.pager.total = 0 ;
           this.loadOrderList();
        }
    }
  }
</script>

<style lang="scss" scoped>
  .info_sm {
    font-size:12px;
    display:flex;
    margin-bottom:20px;
    span {
      flex: 1;
    }
  }
</style>
